import React from 'react';
import './App.scss';
import 'semantic-ui-css/semantic.min.css'
import Header from './containers/Header'
import AppRouter from './containers/AppRouter'
import {ErrorBoundary} from 'react-error-boundary'
import { ErrorsProvider } from './contexts/ErrorsContext'
import { ConfigProvider } from './contexts/ConfigContext'
import { UIProvider } from './contexts/UIContext'

import Moment from 'moment'
import { LoginProvider } from './contexts/LoginContext';

window.Moment = Moment

function ErrorFallback({error, resetErrorBoundary}) {
    localStorage.removeItem('config')

    const resetCache = ()=>{
        //empty all localstorage
        localStorage.clear()

        //redirect the user to /
        window.location.href = '/'
    }

    return (
      <div role="alert">
        <h1>Something went wrong:</h1>

        <p style={{cursor:"pointer"}}onClick={resetCache}>Try reseting your browser cache by <b>clicking here</b> (this will log you out so have your password to hand).</p>
        <p>If the issue persists, please create a XView Support Ticket and we'll investigate the issue ASAP.</p>
        <p>If the problem is preventing you from accessing XView Support Tickets, then please email <a href='mailto:support@xview.app'>support@xview.app</a>.</p>

        
        {/* <pre>{error.message}</pre> */}
      </div>
    )
  }

const CombinedContext = ({children})=>{
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                
            }}
        >
            <ConfigProvider>
                <LoginProvider>
                    <UIProvider>
                        <ErrorsProvider>
                            {children}
                        </ErrorsProvider>
                    </UIProvider>
                </LoginProvider>
            </ConfigProvider>
        </ErrorBoundary>
        
    )
}

function App() {
    return (
        <CombinedContext>
            <Header />
            <AppRouter />
        </CombinedContext>
    );
}

export default App;
